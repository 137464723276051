<template>
  <div style="background-color: #02032f" class="">
    <!-- class="print-wrapper" -->
    <div style="color: #fbce00 !important">
      <p
        style="
          padding: 12px 5px 10px;
          text-align: center;
          font-weight: bold;
          font-size: 19px;
        "
      >
        PRINT MATCHES
      </p>
    </div>

    <div style="background-color: #02032f" class="faso-content-wrapper">
      <section class="print-wrapper pb-3">
        <div class="container">
          <div style="background-color: #02032f" class="row">
            <div
              style="background-color: #02032f; color: black; padding: unset"
              class="league-display-wrapper pt-3 pb-2"
            >
              <div class="fixture-border">
                <div class="row justify-content-center">
                  <div
                    class="col-6 pl-2"
                    style="margin-left: 15px; color: white"
                  >
                    Choose which matches to Print
                  </div>
                </div>
              </div>
            </div>

            <div
              class="select-matches-to-print"
              style="
                background-color: #07074f;
                padding: 12px;
                border-radius: 3px;
                border-bottom: 1px solid white;
              "
            >
              <div class="row">
                <div
                  class="col-6 mb-3 print-main"
                  style="border-radius: 10px !important"
                >
                  <!--<a class="d-block"  href="#">Today's Football</a>-->
                  <router-link
                    class="d-block text-light"
                    :to="{ name: 'print-game', params: { match_count: 48 } }"
                    style="
                      color: #312c04 !important;
                      font-weight: bold;
                      font-size: 14px;
                    "
                    >Today's Football
                  </router-link>
                </div>

                <div class="col-6 mb-3 print-main">
                  <!--<a class="d-block"  href="#">Tomorrow's Football</a>-->
                  <router-link
                    class="d-block text-light"
                    :to="{
                      name: 'print-game',
                      params: {
                        start_date: this.tomorrow,
                        end_date: this.tomorrow,
                        market: '1',
                        match_count: 48,
                      },
                    }"
                    style="
                      color: #312c04 !important;
                      font-weight: bold;
                      font-size: 14px;
                    "
                    >Tomorrow's Football
                  </router-link>
                </div>

                <div class="col-6 mb-3 print-main">
                  <!--<a class="d-block"  href="#">Next 3 Day's Football</a>-->
                  <router-link
                    class="d-block text-light"
                    :to="{
                      name: 'print-game',
                      params: {
                        start_date: this.today,
                        end_date: this.plus_three,
                        market: '1',
                        match_count: 48,
                      },
                    }"
                    style="
                      color: #312c04 !important;
                      font-weight: bold;
                      font-size: 14px;
                    "
                    >Next 3 Day's Football
                  </router-link>
                </div>

                <div class="col-6 mb-3 print-main">
                  <!--<a class="d-block" href="#">Next 7 Day's Football</a>-->
                  <router-link
                    class="d-block text-light"
                    :to="{
                      name: 'print-game',
                      params: {
                        start_date: this.today,
                        end_date: this.plus_seven,
                        market: '1',
                        match_count: 48,
                      },
                    }"
                    style="
                      color: #312c04 !important;
                      font-weight: bold;
                      font-size: 14px;
                    "
                    >Next 7 Day's Football
                  </router-link>
                </div>
              </div>
            </div>

            <div
              class="print-all-matches pt-2 pb-2"
              style="
                background-color: #07074f;
                padding: 12px;
                border-radius: 3px;
              "
            >
              <div class="row">
                <div class="col-12 pl-2">
                  <label style="color: white" class="print-label"
                    >Choose Sports</label
                  >
                  <div class="dropdown">
                    <select v-model="sport" class="select-print">
                      <option value="">All Sports</option>
                      <option
                        v-for="s in sports"
                        :value="s.sport_id"
                        v-bind:key="getKey(s.sport_id)"
                      >
                        {{ s.sport_name }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="col-6 mb-2">
                  <label class="print-label">Choose Period</label>

                  <div class="dropdown">
                    <select v-model="time" class="select-print">
                      <option value="">Choose Period</option>
                      <option :value="today">Today's Highlight</option>
                      <option :value="plus_two">Next 48 hours</option>
                      <option :value="tomorrow">Tomorrow</option>
                      <!--<option :value="p" v-for="(p, i) in period" :key="getKey(i)">{{ i }}</option>-->
                    </select>
                  </div>
                </div>

                <div class="col-6 mb-2">
                  <label class="print-label">Number of matches</label>
                  <div class="dropdown">
                    <select v-model="match_count" class="select-print">
                      <option value="">All Matches</option>
                      <option
                        :value="i"
                        v-for="(m, i) in matches_number"
                        :key="getKey(i)"
                      >
                        {{ m }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <a
                style="
                  background-color: #fbce00 !important;
                  margin-top: 10px;
                  margin-bottom: 20px;
                  color: #312c04;
                  font-weight: bold;
                "
                @click="print_page()"
                class="form-control hover-pointer"
              >
                <div class="col-12 print-match-btn">Print Matches</div>
              </a>

              <div class="col-12 mt-3 mb-1 d-none">
                <div
                  class="custom-control custom-checkbox"
                  style="color: black; margin-left: 40%"
                >
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="defaultUnchecked"
                    name="accept"
                    required="required"
                  />
                  <label
                    class="custom-control-label pt-1"
                    for="defaultUnchecked"
                    >Enable Download</label
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
// const BottomFooter = () => import('./BottomFooter.vue')
// const Trending = () => import('./Trending.vue')
let d = new Date();
let datestring = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
let tomorrow =
  d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() + 1);
let plus_two =
  d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() + 2);
let plus_three =
  d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() + 3);
let plus_seven =
  d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() + 7);
//import axios from "@/services/api";
// import Menu from "./Menu";
export default {
  name: "Print",
  components: {
    // Menu,
    // BottomFooter,
    // Trending,
  },
  data: function () {
    return {
      sport: "",
      time: "",
      match_count: "",
      today: datestring,
      tomorrow: tomorrow,
      plus_two: plus_two,
      plus_three: plus_three,
      plus_seven: plus_seven,
      period: {
        "Today's Highlight": datestring,
        "Next 48 hours": plus_two,
        Tomorrow: tomorrow,
      },
      matches_number: {
        50: "50 Matches",
        100: "100 Matches",
        150: "150 Matches",
        200: "200 Matches",
      },
    };
  },
  methods: {
    getKey: function (index) {
      return Math.random()
        .toString(20)
        .replace("0.", "random-id-" + index + "-");
    },
    print_page: function () {
      let data = {
        start_date: this.today,
        end_date: this.time,
        market: 1,
        match_count: this.match_count,
        sport: this.sport,
      };
      console.log("DATA-->", JSON.stringify(data));
      this.$router.push({
        name: "print-game",
        params: data,
      });
    },
  },
  mounted: function () {
    this.$store.dispatch("setCurrentPage", "print");
  },
  computed: {
    sports: function () {
      return this.$store.state.sports;
    },
    current_page: function () {
      return this.$store.state.current_page;
    },
  },
};
</script>

<style scoped>
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px !important;
  padding-left: 15px !important;
}
.busta-content-wrapper {
  width: 100%;
  float: left;
  /*padding-top: 10.75em;*/
  background-color: black;
  padding-bottom: 4em;
}

.league-display-wrapper {
  font-size: 1em;
  font-weight: bold;
  background-color: var(--darkBackground);
}
.league-display-wrapper {
  width: 100%;
  float: left;
}
.fixture-border {
  float: left;
  width: 100%;
}

.select-matches-to-print a {
  color: #00b40a;
  font-size: 0.7em;
  /* background-color: #ecf1ff; */
  padding: 10px;
  border-radius: 2px;
  width: 100%;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fixture-border {
  float: left;
  width: 100%;
}
.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}
.print-all-matches button {
  color: #202020;
  font-size: 0.7em;
  background-color: #ecf1ff;
  border-color: #ecf1ff;
  padding: 10px;
  border-radius: 2px;
  width: 100%;
  text-align: left;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.print-match-btn button {
  font-size: 14px;
  text-transform: capitalize;
  border: none;
}

.print {
  background-color: #40a3ff;
  color: white;
  height: 40px;
}

.dropdown {
  background-color: #003283;
  /* background-color: #fbce00 !important; */
}

.item-wrapper {
  color: #40a3ff;
  background-color: #003283;
  margin-top: 5px;
  padding: 2px;
  border-radius: 2pt;
  text-decoration: none;
}

.item {
  margin-left: 10px;
}
</style>
